








































































































































































































































































































































































































  @import '~@/assets/styles/helpers/_variables.scss';
a {
  list-style: none;
  text-decoration: none;
}

.vc {
  max-width: 1200px;
  @media(max-width: 992px) { max-width: 920px; }
  display: flex;
  margin: 0 auto;
  &-content{
    width: 50%;
    @media(max-width: 992px) {
      width: 100%;
    }
  }
  &-card{
    // width: 100%;
  }
  &-saldo {
    width: 100%;
    background: #57d3ff;
    color: white;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 32px;
    &-title {
      font-size: 18px;
    }
  }

  &-contas {
    flex-wrap: wrap;
    color: #31304c;
    min-width: 450px;
    font-size: 18px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    align-items: center;
    &-title {
      width: 100%;
      cursor: pointer;
    }
    &-title2 {
      background: #f7f8f8;
      color: #9797ad;
      font-weight: normal;
      font-size: 14px;
      padding-left: 20px;
    }
    &-box {
      a{
        border-bottom: 1px solid #eef1f5;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      width: 100%;
      .image {
        float: left;
        img {
          max-width: 40px;
        }
      }
      &-description{
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 65%;
        @media(max-width: 992px) {
          width: 70%;
        }
        @media(max-width: 760px) {
          width: 60%;
        }
        @media(max-width: 600px) {
          width: 50%;
        }
        big {
          display: flex;
          color: #5076ff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
          margin-left: 10px;
          width: 100%;
          align-items: flex-end;
        }
        small {
          width: 100%;
          display: block;
          margin-left: 10px;
          color: #9797ad;
          font-size: 12px;
          line-height: 12px;
          white-space: nowrap;
          display: inline-block;
        }
      }
      &-value {
        color: #5076ff;
        font-size: 16px;
        float: right;
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.r{
          color: #9797ad;
          font-size: 16px
        }
      }
      &-status {
        font-size: 12px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        &-icon{
          font-size: 18px
        }
      }
    }
  }
}

.box-saldo {
    color: #FFF;
    &-titulo {
      text-align: center;
      font-size: .9rem;
      width: 100%;
      display: flex;
      padding-top: .4rem;
      justify-content: center;

      &-icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__content {
      text-align: center;
     span {
       color: #Fff;
       display: block;
     }

     strong {
       color: #Fff;
       font-size: 1.6rem;
     }
    }
  }

